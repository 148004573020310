import logo from './img/Logo.jpg';
import './App.css';
import React, {useEffect} from "react";
import {initializeApp} from "firebase/app";
import firebaseConfig from "./Config";
import {onAuthStateChanged, getAuth} from "firebase/auth";
import background from "./img/Background.jpg";


const app = initializeApp(firebaseConfig);
const auth = getAuth();
function DeleteFotos() {
    useEffect(() => {
        logincheck();
        window.getDFotos();
    }, [])
    function logincheck(){
        onAuthStateChanged(auth, (user) => {
            if (user) {

            } else {
                window.location.href = "./Login"
            }
        });
    }
    function redirectToBeheer() {
        window.location.href = '.';
    }
    return (
        <div className="App">
            <header>
                <style>{`body {
                    background: linear-gradient(white, white), url(${background}) no-repeat fixed center;
                    background-blend-mode: saturation;
                    background-size: 100% 70%;
                }
                `}</style>
                <div className="nav">
                    <h2><a href="/././">Start</a></h2>
                    <h2><a href="/././Regelement">Regelement</a></h2>
                    <img src={logo} className="logo" alt="logo"/>
                    <h2><a href="/././Fotos">Foto's</a></h2>
                    <h2><a href="/././Contact">Contact</a></h2>
                </div>
            </header>
            <div className="main">
                <button onClick={redirectToBeheer}>Beheer</button>
                <div id="fotolijst">
                    <p className="loading-icon">&#x21BB;</p>
                    <p className="loading-message">Loading...</p>
                </div>
            </div>
            <footer></footer>
        </div>
    );
}

export default DeleteFotos;
