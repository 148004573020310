import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import firebaseConfig from './Config.js';
import { getDatabase, ref, set, onValue } from "firebase/database";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);

window.getRegelement = getRegelement;
window.getFotos = getFotos;
window.getAankondiging = getAankondiging;
window.getActiviteiten = getActiviteiten;

function getRegelement() {
    const iframeContainer = document.getElementById('pdfPreviewContainer');
    const databaseRef = ref(database, 'Regelement');
    onValue(databaseRef, (snapshot) => {
        let pdf = snapshot.val().PDFBase;
        const pdfDataUrl = `data:application/pdf;base64,${pdf}`;

        const downloadLink = document.createElement('a');
        downloadLink.href = `data:application/pdf;base64,${pdf}`;
        downloadLink.download = `Regelement_Vliegvisvereneging_Bergerven.pdf`;
        downloadLink.innerText = 'Download Regelement Vliegvisvereneging Bergerven.pdf';

        const iframe = document.createElement('iframe');
        iframe.width = '100%';
        iframe.height = '500px';
        iframe.src = pdfDataUrl;
        // Append the iframe to the container
        iframeContainer.innerHTML = ''; // Clear previous content
        iframeContainer.appendChild(downloadLink);
        iframeContainer.appendChild(iframe);
    });
}

function getAankondiging() {
    const databaseRef = ref(database, 'Aankondiging');
    onValue(databaseRef, (snapshot) => {
        console.log('Snapshot:', snapshot.val());
        if (snapshot.val() !== "" && snapshot.val() !== null){
            let div = document.getElementById('aankondigingdiv');
            let titel = snapshot.val().Titel;
            let beschrijving = snapshot.val().Beschrijving;
            let image = snapshot.val().ImageBase;
            let titelp = document.createElement('h1');
            titelp.textContent = titel;
            let beschrijvingp = document.createElement('p');
            beschrijvingp.textContent = beschrijving;
            div.appendChild(titelp);

            if (image !== ""){
                let img = document.createElement('img');
                img.src = 'data:image/png;base64,' + image;
                div.appendChild(img);
            }
            div.appendChild(beschrijvingp);
        }
    });
}

function getActiviteiten() {
    const databaseRef = ref(database, 'Activiteiten');
    onValue(databaseRef, (snapshot) => {
        if (snapshot.val() !== "" && snapshot.val() !== null){
        const activiteitenlijst =  Object.values(snapshot.val());
        let div = document.getElementById('activiteitendiv');
        let head = document.createElement('h1');
        head.innerText = "Activiteiten";
        div.appendChild(head);
        activiteitenlijst.forEach(activiteit => {
                let titel = activiteit.Titel;
                let beschrijving = activiteit.Beschrijving;
                let titelp = document.createElement('h2');
                titelp.textContent = titel;
                div.appendChild(titelp);
            });
        }
    });
}

function getFotos() {
    const databaseRef = ref(database, 'Images');
    onValue(databaseRef, (snapshot) => {
        let value = snapshot.val();
        const dataArray = Object.values(value);
        let lijst = document.getElementById("fotolijst");
        lijst.innerHTML = "";

        dataArray.forEach(foto => {
            let div = document.createElement('div');
            div.className = "flitem";

            // Create an image element
            const img = document.createElement('img');
            img.src = 'data:image/png;base64,' + foto.ImageBase;

            // Add a click event listener to open the image in a popup
            img.addEventListener('click', function() {
                openImagePopup(img.src);
            });

            let para = document.createElement('p');
            para.innerText = foto.Beschrijving;

            div.appendChild(img);
            div.appendChild(para);
            lijst.appendChild(div);
        });
    });
}

function openImagePopup(imageSrc) {
    // Create a popup or modal element
    let popup = document.createElement('div');
    popup.className = 'image-popup';

    // Create an image element for the larger image
    let popupImg = document.createElement('img');
    popupImg.src = imageSrc;

    // Create a close button
    let closeButton = document.createElement('button');
    closeButton.innerText = 'X';
    closeButton.addEventListener('click', function() {
        document.body.removeChild(popup);
    });

    // Add the image and close button to the popup
    popup.appendChild(popupImg);
    popup.appendChild(closeButton);

    // Append the popup to the document body
    document.body.appendChild(popup);
}


function displayPdfPreview(pdfBase64Data, filename) {
    const missingPadding = pdfBase64Data.length % 4;
    if (missingPadding) {
        pdfBase64Data += '='.repeat(4 - missingPadding);
    }

    const pdfBlob = b64toBlob(pdfBase64Data, 'application/pdf');
    const pdfUrl = URL.createObjectURL(pdfBlob);

    let div = document.getElementById("pdfPreviewContainer");
    let ifr = document.createElement("iframe");
    ifr.src = pdfUrl;
    ifr.width = "100%";
    ifr.height = "350px";
    ifr.title = filename;
    ifr.type = "application/pdf";

    div.appendChild(ifr);
}

// Function to convert base64 to Blob
function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}



