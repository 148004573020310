import logo from './img/Logo.jpg';
import boten from './img/Boten.jpg';
import groep from './img/groep.jpg';
import f1 from './img/Foto5.jpg';
import f2 from './img/Foto8.jpg';
import f3 from './img/Foto1.jpg';
import './App.css';
import React, {useEffect} from "react";
import { Helmet } from 'react-helmet';
import background from "./img/Background.jpg";


function Home() {
    useEffect(() => {
        window.getAankondiging();
        window.getActiviteiten();
    }, [])
    return (
        <div className="App">
            <Helmet>
                <meta charSet="UTF-8"/>
                <meta name="robots" content="index, follow"/>
                <meta name="description"
                      content="Vliegvisvereniging Bergerven: De perfecte plek voor vliegvisliefhebbers die op zoek zijn naar een onvergetelijke ervaring. Geniet van het gemak van onze ..."/>
                <title>Vliegvisvereniging Bergerven</title>
                <link rel="icon" href={logo} type="image/x-icon"/>
                <link rel="apple-touch-icon" sizes="180x180" href={logo}/>
                <link rel="shortlink" href="https://vvbergerven.be/"/>
                <link rel="canonical" href="https://vvbergerven.be/"/>
                <meta name="revisit-after" content="7 days"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            </Helmet>
            <header>
                <div className="nav">
                    <h2><a href="./">Home</a></h2>
                    <h2><a href="./Regelement">Regelement</a></h2>
                    <img src={logo} className="logo" alt="logo"/>
                    <h2><a href="./Fotos">Foto's</a></h2>
                    <h2><a href="./Contact">Contact</a></h2>
                </div>
                <style >{`body {
                    background: linear-gradient(white, white), url(${background}) no-repeat fixed center;
                    background-blend-mode: saturation;
                    background-size: 100% 70%;
                }
                `}</style>
            </header>
            <div className="main">
                <div id="aankondigingdiv"></div>
                <h1>Welkom bij de</h1>
                <h1>Vliegvisvereniging Bergerven</h1>
                <p>
                    De perfecte plek voor vliegvisliefhebbers die op zoek zijn naar een onvergetelijke ervaring. Geniet
                    van het gemak van onze privéparkeergelegenheid en laat de drukte achter u terwijl u zich
                    onderdompelt in een oase van rust op ons uitgestrekte terrein van <b>34 hectare water</b>.
                </p>
                <p>
                    Met <b>7 clubboten</b> tot uw beschikking, bieden we u de mogelijkheid om uw passie voor vliegvissen op unieke wijze te beleven. Of u nu een ervaren visser bent of net begint, onze vereniging staat garant voor een warme sfeer en een prachtige omgeving die uw hobby naar nieuwe hoogten tilt.
                </p>
                <img className="boot" src={boten} alt="Clubboten"/>
                <p>
                    Ons doel is duidelijk: wij streven ernaar om onze aangesloten leden de ultieme gelegenheid te bieden om te genieten van het vliegvissen, omringd door de natuurlijke schoonheid van Bergerven. Daarnaast omvat uw lidmaatschap automatisch het <b>lidmaatschap van "Limburgs Landschap"</b>, waardoor u niet alleen deel uitmaakt van onze vereniging, maar ook bijdraagt aan het behoud en de ontwikkeling van onze prachtige omgeving.
                </p>
                <p>
                    Ontdek de geneugten van vliegvissen bij de "Vliegvisvereniging Bergerven" - waar passie en natuur samenkomen voor een onvergetelijke ervaring.
                </p>
                <img className="boot" src={groep} alt="Groep"/>
                <p>
                    Wil je meer ontdekken over de wereld van de "Vliegvisvereniging Bergerven" of ben je geïnteresseerd in het beleven van de kunst van het vliegvissen bij ons? Neem gerust contact met ons op:
                    <br/>
                    <br/>
                    <b>Locatie: </b> Weerstandersweg, 3650 Dilsen/Rotem
                    <br/>
                    <br/>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2108.522881812244!2d5.689710519797138!3d51.062865765096554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c0cfc1dbca7cb3%3A0xd47139fddf429a64!2sWeerstandersweg%2C%203650!5e0!3m2!1sen!2sbe!4v1701125621098!5m2!1sen!2sbe"
                        width="70%" height="350" allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade">
                    </iframe>
                    <br/>
                    <br/>
                    <b>E-mail: </b><a className="infohref" href="mailto:vliegvissers.Bergerven@hotmail.com">vliegvissers.Bergerven@hotmail.com</a>
                    <br/>
                    <br/>
                    <b>Telefoon: </b><a className="infohref" href="tel:+32494886201">+32 (0)494 88 62 01</a>
                    <br/>
                    <br/>
                    We staan paraat om al je vragen te beantwoorden en kijken ernaar uit je te verwelkomen in onze vereniging!
                </p>
                <div id="activiteitendiv"></div>
                <div className="fotos">
                    <img className="fotog" src={f1} alt="Foto"/>
                    <img className="fotog" src={f2} alt="Foto"/>
                    <img className="fotog" src={f3} alt="Foto"/>
                </div>
            </div>
            <footer></footer>
        </div>
    );
}

export default Home;
