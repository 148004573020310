import logo from './img/Logo.jpg';
import './App.css';
import React, {useEffect} from "react";
import background from "./img/Background.jpg";

function Fotos() {

    useEffect(() => {
        window.getFotos();
    }, []);
    return (
        <div className="App">
            <header>
                <style>{`body {
                    background: linear-gradient(white, white), url(${background}) no-repeat fixed center;
                    background-blend-mode: saturation;
                    background-size: 100% 70%;
                }
                `}</style>
                <div className="nav">
                    <h2><a href="./">Home</a></h2>
                    <h2><a href="./Regelement">Regelement</a></h2>
                    <img src={logo} className="logo" alt="logo"/>
                    <h2><a href="./Fotos">Foto's</a></h2>
                    <h2><a href="./Contact">Contact</a></h2>
                </div>
            </header>
            <div className="main">
                <div id="fotolijst">
                    <p className="loading-icon">&#x21BB;</p>
                    <p className="loading-message">Loading...</p>
                </div>
            </div>
            <footer></footer>
        </div>
    );
}

export default Fotos;
