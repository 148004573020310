import React, { useEffect } from 'react';
import logo from "./img/Logo.jpg";
import './Regelement.css';
import background from "./img/Background.jpg";

const Regelement = () => {
    useEffect(() => {
            window.getRegelement();
    }, []);

    return (
        <div className="App">
            <header>
                <style>{`body {
                    background: linear-gradient(white, white), url(${background}) no-repeat fixed center;
                    background-blend-mode: saturation;
                    background-size: 100% 70%;
                }
                `}</style>
                <div className="nav">
                    <h2><a href="./">Home</a></h2>
                    <h2><a href="/Regelement">Regelement</a></h2>
                    <img src={logo} className="logo" alt="logo"/>
                    <h2><a href="/Fotos">Foto's</a></h2>
                    <h2><a href="/Contact">Contact</a></h2>
                </div>
            </header>
            <div className="main">
                <h1 className="rglh1">Regelement</h1>

                <div id="pdfPreviewContainer"></div>
            </div>
            <footer></footer>
        </div>
    );
};
export default Regelement;
