import logo from './img/Logo.jpg';
import './App.css';
import React, { useState } from 'react';
import { initializeApp } from "firebase/app";
import firebaseConfig from './Config.js';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import background from "./img/Background.jpg";




const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const  Login = () => {

    const handleSignIn = async () => {
        document.getElementById("errml").textContent = "";
        try {
            let email = document.getElementById('email').value;
            let password = document.getElementById('password').value;
            await signInWithEmailAndPassword(auth, email, password);
            window.location.href = "./Info"
        } catch (error) {
            document.getElementById("errml").textContent = "Email of Wachtwoord is incorrect";
        }
    };

    const handleWW = async () => {
            window.location.href = "./WachtwoorVergeten"
    };


    return (
        <div className="App">
            <style>{`body {
                    background: linear-gradient(white, white), url(${background}) no-repeat fixed center;
                    background-blend-mode: saturation;
                    background-size: 100% 70%;
                }
                `}</style>
            <div className="login">
                <h1>Login</h1>
                <img src={logo} className="logo" alt="logo"/>
                <div>
                    <p>Email:</p>
                    <input id={"email"} type={"text"}/>
                </div>
                <div>
                    <p>Wachtwoord:</p>
                    <input id={"password"} type={"password"}/>
                </div>
                <button className={"btnl"} onClick={handleSignIn}>Login</button>
                <p className={"errorm"} id={"errml"}></p>
                <button className={"btnl"} onClick={handleWW}>Wachtwoord Vergeten</button>
            </div>
        </div>
    );
}

export default Login;
