import React, { useState } from 'react';
import { initializeApp } from "firebase/app";
import firebaseConfig from './Config.js';
import {getDatabase, ref, set, onValue, update} from "firebase/database";
import './Regelement.css';
import './Beheeracties';
import background from "./img/Background.jpg";

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const RegelementBeheer = () => {
    const [pdfPreview, setPdfPreview] = useState('');
    const [base64String, setBase64String] = useState('');

    let pdf = "";
    async function UpdateRegelement() {
        document.getElementById("errorr").textContent = "";
        console.log(base64String);
        if(base64String !== ""){
            const updates = {};
            updates['/Regelement/PDFBase'] = base64String;
            try {
                await update(ref(database), updates);
                window.location.reload();
            } catch (error) {
                console.error('Update failed:', error);
            }
        }
        else {
            document.getElementById("errorr").textContent = "Gelieve een document te selecteren";
        }
    }


    const handleFileSelect = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = function (e) {
                const pdfBinaryData = e.target.result.split(',')[1]; // Extract base64 part
                setBase64String(pdfBinaryData);
                // Display PDF preview
                displayPdfPreview(pdfBinaryData, "Bergerven Regelement.pdf");
            };

            reader.readAsDataURL(file);
        }
    };

    const displayPdfPreview = (pdfBase64Data, filename) => {
        const pdfBlob = b64toBlob(pdfBase64Data, 'application/pdf');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfPreview(<iframe src={pdfUrl} width="100%" height="500px" title={filename} />);
    };

    // Function to convert base64 to Blob
    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    };

    return (
        <div className="beheer">
            <style>{`body {
                    background: linear-gradient(white, white), url(${background}) no-repeat fixed center;
                    background-blend-mode: saturation;
                    background-size: 100% 70%;
                }
                `}</style>
            <h1 className="rglh1">Regelement</h1>
            <input type="file" onChange={handleFileSelect} accept=".pdf"/>
            <div id="pdfPreviewContainer">{pdfPreview}</div>
            <button onClick={UpdateRegelement}>Regelement Opslaan</button>
            <p className="errorm" id="errorr"></p>
        </div>
    );
};
export default RegelementBeheer;