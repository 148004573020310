import React, { useState } from 'react';
import { initializeApp } from "firebase/app";
import firebaseConfig from './Config.js';
import { getDatabase, ref, set, onValue } from "firebase/database";
import "./Fotos.css";
import background from "./img/Background.jpg";

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const PhotoUploader = () => {
    const [images, setImages] = useState(null);
    const [base64Strings, setBase64Strings] = useState('');
    const [progress, setProgress] = useState(0);

    const handleChange = (e) => {
        const selectedImages = Array.from(e.target.files);
        Promise.all(
            selectedImages.map((image) => {
                return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve(reader.result.split(',')[1]);
                    };
                    reader.readAsDataURL(image);
                });
            })
        ).then((base64Array) => {
            setImages(selectedImages);
            setBase64Strings(base64Array);
        });
    };

    function generateRandomString() {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';

        for (let i = 0; i < 10; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters.charAt(randomIndex);
        }

        return result;
    }

    const handleUpload = () => {
        setProgress(0);
        document.getElementById("errorf").textContent = "";
        if(base64Strings.length !== 0){
            let div = document.getElementsByClassName("imagefields");
            const imageArray = Array.from(div);
            let count = 0;
            imageArray.forEach((element, index) => {
                let name = generateRandomString();
                const dbRef = ref(database, 'Images/' + name);
                set(dbRef, {
                    Naam: name,
                    Beschrijving: element.querySelector("TextArea").value,
                    ImageBase: base64Strings[count],
                });
                count++;
            });
        }else{
            document.getElementById("errorf").textContent = "Gelieve fotos aan te duiden";
        }
        setTimeout(function() {
            document.getElementById("fotosa").innerHTML = "";
        }, 3000);
    };

    function redirectToFotos() {
        window.location.href = '/Beheer/Info/Fotos';
    }

    return (
        <div className="ffl beheer">
            <style>{`body {
                    background: linear-gradient(white, white), url(${background}) no-repeat fixed center;
                    background-blend-mode: saturation;
                    background-size: 100% 70%;
                }
                `}</style>
            <h1 className="rglh1">Fotos Toevoegen</h1>
            <input id="finput" type="file" onChange={handleChange} multiple/>
            <div id="fotosa">
                {images && images.map((image, index) => (
                    <div className="imagefields">
                        <img
                            key={index}
                            src={URL.createObjectURL(image)}
                            alt={`Preview ${index}`}
                            style={{width: '50%', margin: '5px'}}
                        />
                        <p>Beschrijving:</p>
                        <textarea id="beschrijving"/>
                    </div>
                ))}
            </div>
            <button onClick={handleUpload}>Fotos Opslaan</button>
            <p className="errorm" id="errorf"></p>
            <button onClick={redirectToFotos}>Fotos Verwijderen</button>
        </div>
    );
};

export default PhotoUploader;
