import logo from './img/Logo.jpg';
import './App.css';
import React from 'react';
import background from "./img/Background.jpg";

function Contact() {
    return (
        <div className="App">
            <header>
                <style>{`body {
                    background: linear-gradient(white, white), url(${background}) no-repeat fixed center;
                    background-blend-mode: saturation;
                    background-size: 100% 70%;
                }
                `}</style>
                <div className="nav">
                    <h2><a href="./">Home</a></h2>
                    <h2><a href="./Regelement">Regelement</a></h2>
                    <img src={logo} className="logo" alt="logo"/>
                    <h2><a href="./Fotos">Foto's</a></h2>
                    <h2><a href="./Contact">Contact</a></h2>
                </div>
            </header>
            <div className="main">
                <h1>Contact Informatie</h1>
                <p>
                    Wil je meer ontdekken over de wereld van de "Vliegvisvereniging Bergerven" of ben je geïnteresseerd
                    in het beleven van de kunst van het vliegvissen bij ons? Neem gerust contact met ons op:
                    <br/>
                    <br/>
                    <b>Locatie Viswater: </b> Weerstandersweg, 3650 Dilsen/Rotem
                    <br/>
                    <br/>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2108.522881812244!2d5.689710519797138!3d51.062865765096554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c0cfc1dbca7cb3%3A0xd47139fddf429a64!2sWeerstandersweg%2C%203650!5e0!3m2!1sen!2sbe!4v1701125621098!5m2!1sen!2sbe"
                        width="70%" height="350" allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade">
                    </iframe>
                    <br/>
                    <br/>
                    <b>E-mail: </b><a className="infohref" href="mailto:vliegvissers.Bergerven@hotmail.com">vliegvissers.Bergerven@hotmail.com</a>
                    <br/>
                    <br/>
                    <b>Telefoon: </b><a className="infohref" href="tel:+32494886201">+32 (0)494 88 62 01</a>
                    <br/>
                    <br/>
                    <b>Locatie Secretaris: </b> Molenweg 119, 3680 Neeroeteren
                    <br/>
                    <br/>
                    We staan paraat om al je vragen te beantwoorden en kijken ernaar uit je te verwelkomen in onze vereniging!
                </p>
            </div>
            <footer></footer>
        </div>
    );
}

export default Contact;
